'use strict';
var wishlist = require('../wishlist/wishlist.js');
var tileBase = require('core/product/tile');

tileBase.methods.swatchSwitch = function(el) {
    console.log("Swatch Switch");
    var $swatch = el;
    var $tileParent = $swatch.parents('.product-tile');
    var pid = $tileParent.attr('data-pid');
    var $tileImage = $tileParent.find('.product-tile-image:not(.cloned-tile-image)');
    var $tileImageHover = $tileParent.find('.product-tile-image-secondary');
    var $tileImageContainer = $tileParent.find('.product-tile-image-container');

    $tileParent.find('.swatch.selected').removeClass('selected');
    $swatch.addClass('selected');

    // If swatch has image URL, use it to update current tile images
    if ($swatch.data('hover-image') && $swatch.data('hover-image') != '') {
        // Clone current image element, keep it hidden until new images finish loading (prevents visual flashing)
        var $newTileImage = $tileImage.clone().removeAttr('loading'); // loading="lazy" images do not preload if display: none;
        $newTileImage.addClass('d-none cloned-tile-image');
        $newTileImage.insertAfter($tileImage);

        // Just after new image loads...
        $newTileImage.one('load', () => {
            // Replace image category class
            $tileImageContainer.removeClass('model-shot-tile product-shot-tile video-thumbnail-tile product-video-tile');
            if ($swatch.data('image-category')) {
                $tileImageContainer.addClass($swatch.data('image-category') + '-tile');
            }
            // Swap out old image with new one
            $tileImage.replaceWith($newTileImage.removeClass('d-none cloned-tile-image'));
        });

        // Replace main image and secondary image
        var swatchImageUrl = $swatch.data('hover-image');
        $newTileImage.attr('src', swatchImageUrl);
        if ($tileImageHover.length > 0 && $swatch.data('hover-image-secondary')) {
            var swatchSecondaryImageUrl = $swatch.data('hover-image-secondary');
            $newTileImage.data('alt-img-url', swatchSecondaryImageUrl);
            $tileImageHover.attr('src', swatchSecondaryImageUrl);
        }
    }

    //replace main link and Quickview link
    var swatchLink = $swatch.hasClass('swatch-link') ? $swatch : $swatch.parents('.swatch-link');
    var splitSwatchLink = swatchLink.attr('href').split('?');
    var swatchLinkAttributes = splitSwatchLink[1];
    // this is for the mainImageLink
    var mainImageQueryString = swatchLinkAttributes;
    // this is for the quickview
    var quickviewQueryString = swatchLinkAttributes;

    //  New for AB - need to account for pid being in the querystring
    if (swatchLinkAttributes.indexOf('pid') != -1) {
        // then split on &
        var queryStringArray = swatchLinkAttributes.split('&');
        var newQSArray = [];
        queryStringArray.forEach((queryAttr, _i) => {
            if (queryAttr.indexOf('pid') == -1) {
                newQSArray.push(queryAttr);
            }
        });
        // this is for the mainImageLink
        mainImageQueryString = swatchLinkAttributes;

        // this is for the quickview
        quickviewQueryString = newQSArray.join('&');
    }

    //main Image Link
    var mainImageLink = $tileParent.find('.product-tile-image-link');
    var mainImageLinkSplit = mainImageLink.attr('href').split('?');
    mainImageLink.attr('href', mainImageLinkSplit[0] + '?' + mainImageQueryString);

    //main Quickview Link
    var $mainQuickViewLink = $tileParent.find('a.quickview');
    if ($mainQuickViewLink.length) {
        var mainQuickViewLinkSplit = $mainQuickViewLink.attr('href').split('?');
        //this is assuming that every swatch link will not contain a pid in the attributes
        $mainQuickViewLink.attr('href',  mainQuickViewLinkSplit[0] + '?' + 'pid=' + pid + '&' + quickviewQueryString);
    }

    //main Wishlist Link
    if ($swatch.attr('data-wishlistpid')) {
        var $mainWishlistLink = $tileParent.find('a.wishlist');
        if ($mainWishlistLink.length) {
            $mainWishlistLink.attr('data-wishlistpid', $swatch.attr('data-wishlistpid'));

            // Make heart icon accurate
            wishlist.updateWishlistLinkData($mainWishlistLink);
        }
    } else {
        // swatch doesn't have one... so we either need to revert to master or just unset..., this may also work in the case of a variation-group

        var $mainWishlistLink = $tileParent.find('a.wishlist');
        if ($mainWishlistLink.length) {
            var swatchColorValue = $swatch.attr('data-color-value');
            wishlist.updateWishlistLinkData($mainWishlistLink, swatchColorValue);
        }
    }

    // Trigger custom event for swatch selected
    $('body').trigger('tile:swatchSelected', $swatch);
}

module.exports = tileBase;
