module.exports = require('core/components/menu');

module.exports.mobileMenuToggle = function () {
    $('[data-toggle=d-none]').click(function () {
        $($(this).data('target')).toggleClass('d-none');
    })
};
var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};
module.exports.dropdownMenu = function() {
        // Custom dropdown behaviors for top menu
        var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"], .custom-dropdown:not(.disabled) [data-toggle="dropdown"] span');
        $dropdownMenu.on('click', event => {
            event.stopPropagation(); // Prevent Bootstrap dropdown click events
            if (window.isMobile()) {
                // Drawer behavior on mobile
                event.preventDefault();
                var $dropdownLink = event.target.nodeName == 'SPAN' ? $(event.target).closest('a') : $(event.target);
                var $dropdown = $dropdownLink.closest('.custom-dropdown');
                var $li = $('<li class="dropdown-item top-category" role="button"></li>');
                var $closeMenu = $('<li class="nav-menu"></li>');
                var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                $li.append(link);
                $closeMenu.append($('.close-menu').first().clone());
                $dropdown.children('.dropdown-menu')
                    .prepend($li)
                    .prepend($closeMenu)
                    .attr('aria-hidden', 'false')
                    .find('a').removeAttr('tabindex', '-1');
                $dropdown.addClass('show');
                $dropdownLink.attr('aria-expanded', 'true');
                $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                $(link).focus();
                $('.dropdown-menu').scrollTop(0);
            } else {
                // Restore link behavior on desktop
                link = $(event.target).attr('href');
                if (link) {
                    location.href = $(event.target).attr('href');
                }
            }
        });

        // Desktop - open menu using hoverIntent to prevent unintentional opening
        $dropdownMenu.hoverIntent({
            over: event => {
                if (!window.isMobile()) {
                    var eventElement = event.target;
                    // Close all dropdowns
                    $('.navbar-nav > li').each((index, element) => {
                        if (!$.contains(element, eventElement)) {
                            var $navItem = $(element);
                            $navItem.find('.show').each(() => {
                                clearSelection(element);
                            });
                            if ($navItem.hasClass('show')) {
                                $navItem.removeClass('show');
                                $navItem.children('ul.dropdown-menu').removeClass('show');
                                $navItem.children('.nav-link').attr('aria-expanded', 'false');
                            }
                        }
                    });
                    // Open current dropdown
                    $(eventElement).parent().addClass('show');
                    $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                    $(eventElement).attr('aria-expanded', 'true');
                }
            },
            out: () => {
                // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
            },
            interval: 50
        });
         // Desktop - close menu on mouseleave
         $dropdownMenu.parent().on('mouseleave', event => {
            if (!window.isMobile()) {
                var $dropdown = $(event.currentTarget);
                // Close current dropdown
                $dropdown.removeClass('show');
                $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
                $dropdown.children('.nav-link').attr('aria-expanded', 'false');
            }
        });

}
