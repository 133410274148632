'use strict';

/**
 * This file is responsible for wiring up the custom marketingCLoud events for cart abandon.
 * Where possible all will be contained here and glom on to custom events that get fired during cart interation
 */
const collectUtils = require('./marketingCloud/collectUtils');
const mcEnabled = $('#collectData').data('mc-enabled');

function initCollectUtils() {
    if (mcEnabled) {
        window.collect = new collectUtils.collectUtils;
    }
}

function trackAddToCart() {
    if (mcEnabled) {
        $('body').on('product:afterAddToCart', function (e, data) {
            console.log(data);
            window.collect.trackCart();
        });
    }
}

function trackAddBonusProduct() {
    if (mcEnabled) {
        $('body').on('product:afterAddBonusProduct', function (e, data) {
            console.log("Tracking Bonus Product");
            window.collect.trackCart();
        });
    }
}

function trackCartUpdate() {
    // called when an item is removed... qty modified, or the edit product quickview is used
    if (mcEnabled) {
        $('body').on('cart:update', function (e, data) {
            console.log(data);
            window.collect.trackCart();
        });
    }
}


function trackSaveForLater() {
    // this handles when an item is savedForLater or added to cart from save for later
    if (mcEnabled) {
        $('body').on('cart:saveforlater:update', function (e, data) {
            console.log(data);
            window.collect.trackCart();
        })
    }
}

function trackPageLoad() {
    if (mcEnabled) {
        $(window).on('load', window.collect.pageload);
    }
}

// bind to the event thrown in login/login.js
function registerSetUserInfo() {
    if (mcEnabled) {
        $('form.registration').on('login:register:success', function(e, data) {
            if(data && data.contactId) {
                window.collect.setUserInfo(data.contactId);
            }
            if (data && data.email) {
                window.collect.setUserInfo(data.email);
            }
        });
    }
}

// bind to the event thrown in login/login.js
function loginSetUserInfo() {
    if (mcEnabled) {
        $('form.login').on('login:success', function (e, data) {
            if(data && data.contactId) {
                window.collect.setUserInfo(data.contactId);
            }
        });
    }
}

// bind to the event thrown in emailSignup/emailSignup.js
function emailSignUpSetUserInfo() {
    if (mcEnabled) {
        $('body').on('emailsignup:success', function (e, data) {
            if(data && data.email) {
                window.collect.setUserInfo(data.email);
            }
        });
    }
}

module.exports = {
    initCollectUtils: initCollectUtils,
    trackPageLoad: trackPageLoad,
    trackAddToCart: trackAddToCart,
    trackAddBonusProduct: trackAddBonusProduct,
    trackCartUpdate: trackCartUpdate,
    trackSaveForLater: trackSaveForLater,
    loginSetUserInfo: loginSetUserInfo,
    registerSetUserInfo: registerSetUserInfo,
    emailSignUpSetUserInfo: emailSignUpSetUserInfo
};

